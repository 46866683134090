@font-face {
font-family: 'marianne';
src: url(/_next/static/media/d8d156f058e97361-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: 'marianne';
src: url(/_next/static/media/23c9e5fb3281b3bd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'marianne';
src: url(/_next/static/media/38d7e532303165ab-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'marianne';
src: url(/_next/static/media/4b521e8198f4604a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'marianne';
src: url(/_next/static/media/6d8d8dfacab5a192-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: 'marianne';
src: url(/_next/static/media/21121b35b3f73ab2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: 'marianne Fallback';src: local("Arial");ascent-override: 88.12%;descent-override: 22.99%;line-gap-override: 0.00%;size-adjust: 109.60%
}.__className_26b3f8 {font-family: 'marianne', 'marianne Fallback'
}.__variable_26b3f8 {--font-marianne: 'marianne', 'marianne Fallback'
}

